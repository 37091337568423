import { navigate, PageProps } from 'gatsby';
import { GenericData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';

import Variant from '~/components/List/Item/Variant';
import CMSView from '~/components/View';
import List from '~/containers/Admin/List';
import IconChevronLeft from '~/icons/ChevronLeft';
import params from '~/params/page.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';
import useChannel from '~/utils/useChannel';
import useEspace from '~/utils/useEspace';

const SpaceChannelVariants: FC<PageProps & UserProps> = function ({
  location,
  user,
  params: { channelId, id: espaceId },
}) {
  const [espace] = useEspace({ id: espaceId, pathname: location.pathname });
  const [channel, items] = useChannel({
    channelId,
    espaceId,
    admin: true,
    pathname: location.pathname,
  });

  const model = new GenericData({
    espaceId,
    params,
    collectionName: 'variants',
    orderDirection: 'desc',
    orderField: 'label',
    parentCollectionName: 'channels',
    parentId: channelId,
  });

  const handleCloseOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    return navigate(-1);
  };

  return (
    <TemplateAdmin>
      <CMSView>
        <List
          allowSorting
          itemList={Variant}
          itemPathnamePrefix={`/admin/espaces/${espaceId}/channels/${channelId}/variants/`}
          menu={{
            left: [
              {
                icon: IconChevronLeft,
                label: 'Revenir à la page précédente',
                onClick: handleCloseOnClick,
              },
            ],
          }}
          model={model}
          search={location.search}
          subMenu={items}
          title={
            espace && channel ? `${espace?.label} ⇾ ${channel?.label}` : ''
          }
          user={user}
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(SpaceChannelVariants, 'admin');
